import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_HowToDo = () => {
    const seo = {
        metaDesc: 'How to Do Patent Search Yourself (DIY) The Right Way? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="How to Do Patent Search Yourself (DIY) The Right Way? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>How to Do Patent Search Yourself (DIY) The Right Way?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2>How to Do Patent Search Yourself (DIY) The Right Way?</h2>
                                <h3><b>1. Start with the USPTO’s research system</b></h3>
                                <p><span style={{ fontWeight: 400 }}>It’s best to visit a USPTO research facility.&nbsp; The one in Alexandria,
                                    Virginia is preferred because its computers have the Exam Assisted Searching Tool (EAST) and you can talk
                                    directly with examiners and other staffers, who are there to assist researchers.&nbsp; EAST is a free tool,
                                    though you have to pay for printing.&nbsp; With EAST, you can search via class or subclass, Boolean or
                                    keyword terms, or a combination of both.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>You can visit a Patent and Trademark Depository Library as well.&nbsp; These are
                                    located in major cities.&nbsp; The USPTO’s website lists where they are and their hours of operation.&nbsp;
                                    These do not have EAST but have the Web-based Examiner Search Tool (WEST).&nbsp; The libraries also do not
                                    have information related to foreign patents or non-patent materials, such as journals.</span></p>
                                <p><span style={{ fontWeight: 400 }}>If those aren’t options, you can also start with an online or patent database
                                    search.&nbsp; The USPTO, European Patent Office and Google, for example, offer this service for free and
                                    there are other fee-based search tools.&nbsp; Though the records here may not go as far back as EAST, the
                                    databases are oftentimes easier to navigate.&nbsp;</span></p>
                                <h3><b>2. Narrow down your search terms</b></h3>
                                <p><span style={{ fontWeight: 400 }}>Become familiar enough with your patent application, including the abstract,
                                    specification and claims, so you can come up with Boolean search terms and the classification that best
                                    describes the nature of your invention.&nbsp; These search terms need to be thorough and exact in order for
                                    you to find patents already in the USPTO’s system, so coming up with synonyms may be helpful.&nbsp; The
                                    USPTO website can also help you navigate the myriad of classifications.&nbsp; It can take some trial and
                                    error to figure out exactly what you’re searching for and how to find it.</span></p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_HowToDo;